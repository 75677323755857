@font-face {
    font-family: "Finntype";
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: url("./FINNTypeStrippet-Light.woff") format("woff");
}

@font-face {
    font-family: "Finntype";
    font-style: normal;
    font-weight: bold;
    font-display: block;
    src: url("./FINNTypeStrippet-Medium.woff") format("woff");
}
